<template>
	<v-dialog :value="isShowing" width="700" persistent>
		<v-card>
			<v-card-title class="headline">Архивировать заявку</v-card-title>

			<v-card-text>
				<v-row style="margin-top: 30px">
					<v-col v-if="areExtraDataShowing">
						<span class="section-label">Выявленные проблемы:</span>

						<div class="checkboxes">
							<v-checkbox
								v-for="(problem, i) in potentialProblems"
								:key="i"
								:label="problem"
								:value="problem"
								hide-details
								:disabled="isFormSubmitting"
								v-model="selectedProblems"
							/>
						</div>
					</v-col>

					<v-col>
						<span class="section-label">Комментарий диспетчера:</span>

						<v-textarea
							v-model="commentInput"
							:disabled="isFormSubmitting"
							label="Комментарий"
							no-resize
						/>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					v-if="areExtraDataShowing"
					outlined
					@click="clearAndCloseModal"
					:disabled="isFormSubmitting"
				>
					Закрыть
				</v-btn>
				<v-btn color="primary" @click="archiveTicket" :loading="isFormSubmitting">
					Архивировать
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import TicketService from "@/services/TicketService";
import { mapActions } from "vuex";

export default {
	name: "ArchiveTicketModalComponent",
	props: {
		ticketId: {
			type: Number,
			required: true,
			default: 0
		},
		areExtraDataShowing: {
			type: Boolean,
			required: false,
			default: true
		},
		potentialProblems: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data: () => ({
		problems: ["Двухвазное кз на землю", "Автоматика не отработала", "Однофазное на землю"],
		selectedProblems: [],
		isShowing: false,
		isFormSubmitting: false,
		commentInput: ""
	}),
	methods: {
		...mapActions(["markAsVisited"]),
		show() {
			this.isShowing = true;
		},
		clearAndCloseModal() {
			this.isShowing = false;
		},
		async archiveTicket() {
			this.isFormSubmitting = true;

			await this.markAsVisited(this.ticketId);
			await TicketService.archiveTicket(
				this.ticketId,
				this.selectedProblems.join(";"),
				this.commentInput
			);

			this.isFormSubmitting = false;

			this.clearAndCloseModal();
			if (this.$route.path !== "/") {
				await this.$router.push("/");
			}
		}
	}
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.section-label {
	color: #333;
	font-size: 18px;
	border-bottom: 1px solid $primary;
	display: inline-block;
	margin-bottom: 20px;
}
</style>
